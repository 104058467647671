import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import Badge, { BadgeVariant } from 'components/Badge'
import FewPoolCard from 'components/earn/v2/FewPoolCard'
import SingleTokenPoolCard from 'components/earn/v2/SingleTokenPoolCard'
import Loader from 'components/Icons/LoadingSpinner'
import { ChainId } from 'eth-mainnet-few-sdk-core-2'
import { useEarnTvl } from 'hooks/useEarnTvl'
import { useEffect, useState } from 'react'
import { Bookmark } from 'react-feather'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { ReactComponent as BlastPoint } from '../../assets/svg/blast_point.svg'
import { ReactComponent as GoldPoint } from '../../assets/svg/gold_point.svg'
import { ReactComponent as RingLogo } from '../../assets/svg/logo.svg'
import { AutoColumn } from '../../components/Column'
import { CardBGImage, CardNoise, CardSection, DataCard } from '../../components/earn/v2/styled'
import Row, { RowBetween } from '../../components/Row'
import {
  BLAST_GOLD_STAKING_REWARDS_TOKEN_INFO,
  NEW_STAKING_REWARDS_INFO,
  NEW_STAKING_REWARDS_TOKEN_INFO,
  STAKING_REWARDS_INFO,
  STAKING_REWARDS_TOKEN_INFO,
  useBlastGoldStakingTokenInfo,
  useNewStakingInfo,
  useNewStakingTokenInfo,
  useStakingInfo,
  useStakingTokenInfo,
} from '../../state/stake/hooks'
import { ExternalLink, ThemedText } from '../../theme'
import Point from './Point'

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 640px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 48px 8px 0px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

export const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
flex-direction: column;
`};
`

export function formatTvl(number: number) {
  if (number < 1000) {
    return Math.round(number)
  } else if (number < 1e6) {
    return (number / 1e3).toFixed(2) + 'K'
  } else if (number < 1e9) {
    return (number / 1e6).toFixed(2) + 'M'
  } else {
    return (number / 1e9).toFixed(2) + 'B'
  }
}

export default function Earn() {
  const { chainId } = useWeb3React()
  const theme = useTheme()

  const stakingInfos = useStakingInfo()
  const newStakingInfos = useNewStakingInfo()
  const stakingTokenInfos = useStakingTokenInfo()
  const newStakingTokenInfos = useNewStakingTokenInfo()
  const blastGoldStakingTokenInfos = useBlastGoldStakingTokenInfo()
  const allTvl = useEarnTvl()

  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  // toggle copy if rewards are inactive
  const oldStakingLpRewardsExist = Boolean(
    typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0
  )
  const oldStakingTokenRewardsExist = Boolean(
    typeof chainId === 'number' && (STAKING_REWARDS_TOKEN_INFO[chainId]?.length ?? 0) > 0
  )
  const newStakingLpRewardsExist = Boolean(
    typeof chainId === 'number' && (NEW_STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0
  )
  const newStakingTokenRewardsExist = Boolean(
    typeof chainId === 'number' && (NEW_STAKING_REWARDS_TOKEN_INFO[chainId]?.length ?? 0) > 0
  )
  const blastGoldStakingTokenRewardsExist = Boolean(
    typeof chainId === 'number' && (BLAST_GOLD_STAKING_REWARDS_TOKEN_INFO[chainId]?.length ?? 0) > 0
  )

  const dispalyRGBPool = Boolean(chainId === ChainId.BLAST_SEPOLIA)
  const dispalyBlastPoint = Boolean(chainId === ChainId.BLAST)

  const RING = chainId === ChainId.BLAST ? 'Ring Point' : 'Ring Governance Token'

  const [isActivityTime, setIsActivityTime] = useState(false)
  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const targetTimestamp = 1730995200 // End in Nov 8
    if (currentTimestamp < targetTimestamp) {
      setIsActivityTime(true)
    } else {
      setIsActivityTime(false)
    }
  }, [])

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <div className="flex gap-1 items-center">
          <Bookmark size={20} />
          {isActivityTime ? (
            <>
              <span>3x APR Boost for the new ETH-ETHX pool!</span>
              <Badge variant={BadgeVariant.BRANDED} className="!py-0.5 !px-1 text-xs">
                3x APY
              </Badge>
            </>
          ) : (
            <span>Introducing the new ETH-ETHx pool.</span>
          )}
        </div>
        <DataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <ThemedText.DeprecatedWhite fontWeight={600}>
                  <Trans>Ring liquidity mining</Trans>
                </ThemedText.DeprecatedWhite>
                {dispalyBlastPoint && (
                  <ThemedText.DeprecatedWhite fontWeight={600} fontSize={22}>
                    <Trans>
                      <span style={{ color: '#FCFC03' }}>$ {formatTvl(allTvl)} </span>
                      TVL
                    </Trans>
                  </ThemedText.DeprecatedWhite>
                )}
              </RowBetween>
              <RowBetween>
                <ThemedText.DeprecatedWhite fontSize={14}>
                  <Trans>Deposit your Liquidity Provider tokens to receive {RING}.</Trans>
                </ThemedText.DeprecatedWhite>
              </RowBetween>
              <ExternalLink
                style={{ color: theme.white, textDecoration: 'underline' }}
                target="_blank"
                href="https://twitter.com/ProtocolRing/status/1763835177136955693"
              >
                <ThemedText.DeprecatedWhite fontSize={14}>
                  <Trans>Read more about Ring Point</Trans>
                </ThemedText.DeprecatedWhite>
              </ExternalLink>
              <Link style={{ color: theme.white, textDecoration: 'underline' }} to="/redeem">
                <ThemedText.DeprecatedWhite fontSize={14}>
                  <Trans>Redeem Ring Point To Ring Token</Trans>
                </ThemedText.DeprecatedWhite>
              </Link>
              {dispalyBlastPoint ? (
                <ThemedText.DeprecatedWhite fontSize={14}>
                  <Trans>
                    <Point />
                  </Trans>
                </ThemedText.DeprecatedWhite>
              ) : (
                ''
              )}
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </DataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <ThemedText.DeprecatedMediumHeader style={{ marginTop: '0.5rem' }}>
            <Row>
              Earning Blast Gold
              <GoldPoint width={20} height={20} style={{ margin: 'auto 4px' }} />
            </Row>
            <ThemedText.DeprecatedBlack fontSize={14}>
              <Trans>Join the Ring Protocol staking program and earn generous Blast Gold Point rewards!</Trans>
              <ExternalLink
                style={{ color: theme.darkMode ? theme.white : theme.black, textDecoration: 'underline' }}
                target="_blank"
                href="https://x.com/ProtocolRing/status/1833928324370112615"
              >
                <ThemedText.DeprecatedBlack fontSize={14}>
                  <Trans>Read more about The Gold $Ring Program</Trans>
                </ThemedText.DeprecatedBlack>
              </ExternalLink>
            </ThemedText.DeprecatedBlack>
          </ThemedText.DeprecatedMediumHeader>
        </DataRow>

        <PoolSection>
          {blastGoldStakingTokenRewardsExist && blastGoldStakingTokenInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !blastGoldStakingTokenRewardsExist ? (
            'No active rewards'
          ) : (
            blastGoldStakingTokenInfos?.map((stakingInfo) => {
              // need to sort by added liquidity here
              return <SingleTokenPoolCard key={stakingInfo.key} stakingInfo={stakingInfo} />
            })
          )}
        </PoolSection>

        <DataRow style={{ alignItems: 'baseline' }}>
          <ThemedText.DeprecatedMediumHeader style={{ marginTop: '0.5rem' }}>
            <Row>
              Earning Blast Points
              <BlastPoint width={20} height={20} style={{ margin: 'auto 4px' }} />
              Gold
              <GoldPoint width={20} height={20} style={{ margin: 'auto 4px' }} />
              $RING
              <RingLogo width={19} height={19} style={{ marginLeft: '4px' }} />
            </Row>
          </ThemedText.DeprecatedMediumHeader>
        </DataRow>

        <PoolSection>
          {newStakingLpRewardsExist && newStakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !newStakingLpRewardsExist ? (
            'No active rewards'
          ) : (
            newStakingInfos?.map((stakingInfo) => {
              // need to sort by added liquidity here
              return <FewPoolCard key={stakingInfo.key} stakingInfo={stakingInfo} />
            })
          )}
          {/* <div style={{ margin: 'auto' }}> Coming soon </div> */}
        </PoolSection>

        <DataRow style={{ alignItems: 'baseline' }}>
          <ThemedText.DeprecatedMediumHeader style={{ marginTop: '0.5rem' }}>
            <Row>
              Earning Blast Points
              <BlastPoint width={20} height={20} style={{ margin: 'auto 4px' }} />
              $RING
              <RingLogo width={19} height={19} style={{ marginLeft: '4px' }} />
            </Row>
          </ThemedText.DeprecatedMediumHeader>
        </DataRow>

        <PoolSection>
          {newStakingTokenRewardsExist && newStakingTokenInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !newStakingTokenRewardsExist ? (
            'No active rewards'
          ) : (
            newStakingTokenInfos?.map((stakingInfo) => {
              // need to sort by added liquidity here
              return <SingleTokenPoolCard key={stakingInfo.key} stakingInfo={stakingInfo} />
            })
          )}
          {/* <div style={{ margin: 'auto' }}> Coming soon </div> */}
        </PoolSection>

        {dispalyRGBPool ? (
          <div>
            <DataRow style={{ alignItems: 'baseline' }}>
              <ThemedText.DeprecatedMediumHeader style={{ marginTop: '0.5rem' }}>
                <Trans>RGB LP pools</Trans>
              </ThemedText.DeprecatedMediumHeader>
            </DataRow>

            <PoolSection>
              {oldStakingLpRewardsExist && stakingInfos?.length === 0 ? (
                <Loader style={{ margin: 'auto' }} />
              ) : !oldStakingLpRewardsExist ? (
                'No active rewards'
              ) : (
                stakingInfos?.map((stakingInfo) => {
                  // need to sort by added liquidity here
                  return <FewPoolCard key={stakingInfo.key} stakingInfo={stakingInfo} />
                })
              )}
              {/* <div style={{ margin: 'auto' }}> Coming soon </div> */}
            </PoolSection>

            <DataRow style={{ alignItems: 'baseline' }}>
              <ThemedText.DeprecatedMediumHeader style={{ marginTop: '0.5rem' }}>
                <Trans>RGB single token pools</Trans>
              </ThemedText.DeprecatedMediumHeader>
            </DataRow>

            <PoolSection>
              {oldStakingTokenRewardsExist && stakingTokenInfos?.length === 0 ? (
                <Loader style={{ margin: 'auto' }} />
              ) : !oldStakingTokenRewardsExist ? (
                'No active rewards'
              ) : (
                stakingTokenInfos?.map((stakingInfo) => {
                  // need to sort by added liquidity here
                  return <SingleTokenPoolCard key={stakingInfo.key} stakingInfo={stakingInfo} />
                })
              )}
              {/* <div style={{ margin: 'auto' }}> Coming soon </div> */}
            </PoolSection>
          </div>
        ) : (
          ''
        )}
      </AutoColumn>
    </PageWrapper>
  )
}
