import { Widget, WidgetConfig } from '@rango-dev/widget-embedded'
import styled from 'styled-components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

const PageWrapper = styled.div`
  max-width: 680px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 68px 8px 0px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

export default function Rango() {
  const isDarkMode = useIsDarkMode()
  const wormholeTheme: 'dark' | 'light' | 'auto' | undefined = isDarkMode ? 'dark' : 'light'

  const config = {
    from: {
      blockchain: 'ETH',
      token: {
        blockchain: 'ETH',
        address: null,
        symbol: 'ETH',
      },
      blockchains: [
        'STARKNET',
        'TRON',
        'BTC',
        'COSMOS',
        'OSMOSIS',
        'NEUTRON',
        'NOBLE',
        'DYDX',
        'SOLANA',
        'MAYA',
        'THOR',
        'LTC',
        'BCH',
        'STARGAZE',
        'CRYPTO_ORG',
        'CHIHUAHUA',
        'BANDCHAIN',
        'COMDEX',
        'REGEN',
        'IRIS',
        'EMONEY',
        'JUNO',
        'STRIDE',
        'MARS',
        'TERRA',
        'BITSONG',
        'AKASH',
        'KI',
        'PERSISTENCE',
        'KUJIRA',
        'SENTINEL',
        'INJECTIVE',
        'SECRET',
        'KONSTELLATION',
        'STARNAME',
        'BITCANNA',
        'UMEE',
        'DESMOS',
        'LUMNETWORK',
        'DASH',
        'DOGE',
        'BLAST',
        'ETH',
      ],
      pinnedTokens: [],
    },
    to: {
      blockchain: 'BLAST',
      token: {
        blockchain: 'BLAST',
        address: null,
        symbol: 'ETH',
      },
      blockchains: [
        'STARKNET',
        'TRON',
        'BTC',
        'COSMOS',
        'OSMOSIS',
        'NEUTRON',
        'NOBLE',
        'DYDX',
        'SOLANA',
        'MAYA',
        'THOR',
        'LTC',
        'BCH',
        'STARGAZE',
        'CRYPTO_ORG',
        'CHIHUAHUA',
        'BANDCHAIN',
        'COMDEX',
        'REGEN',
        'IRIS',
        'EMONEY',
        'JUNO',
        'STRIDE',
        'MARS',
        'TERRA',
        'BITSONG',
        'AKASH',
        'KI',
        'PERSISTENCE',
        'KUJIRA',
        'SENTINEL',
        'INJECTIVE',
        'SECRET',
        'KONSTELLATION',
        'STARNAME',
        'BITCANNA',
        'UMEE',
        'DESMOS',
        'LUMNETWORK',
        'DASH',
        'DOGE',
        'ETH',
        'BLAST',
      ],
    },
    liquiditySources: [],
    // This API key is only for test purpose. Don't use it in production.
    apiKey: '7cb06943-d671-4539-8421-c00385edd5de',
    // This project id is only for test purpose. Don't use it in production.
    // Get your Wallet Connect project id from https://cloud.walletconnect.com/
    walletConnectProjectId: 'ae4b026c80cba8169f0b8b5f8663dc51',
    // Here, give your email and URL.
    theme: {
      // the default theme for the widget
      mode: wormholeTheme,
    },
    features: {
      theme: 'hidden',
    },
  }
  return (
    <PageWrapper>
      <Widget config={config as WidgetConfig} />
    </PageWrapper>
  )
}
