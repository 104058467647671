import { Trans } from '@lingui/macro'
import { useAccountDrawer } from 'components/AccountDrawer'
import { NewIcon } from 'components/Icons'
import Web3Status from 'components/Web3Status'
import { useInfoUrl } from 'hooks/useInfoUrl'
import { useIsAirdropPage } from 'hooks/useIsAirdropPage'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { useIsRangoPage } from 'hooks/useIsRangoPage'
import { useIsWrapPage } from 'hooks/useIsWrapPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { UniIcon } from 'nft/components/icons'
import { ReactNode, useCallback } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
import { More } from './More'
// import { MenuDropdown } from './MenuDropdown'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()

  const isPoolActive = useIsPoolsPage()
  const isWrapActive = useIsWrapPage()
  const isBridgeActive = useIsRangoPage()
  const isAirdropActive = useIsAirdropPage()
  const infoUrl = useInfoUrl()

  return (
    <>
      {/* <MenuItem href="/ring" isActive={pathname.startsWith('/ring')}>
        <Trans>Ring</Trans>
      </MenuItem> */}
      <MenuItem href="/swap" isActive={pathname.startsWith('/swap')}>
        <Trans>Swap</Trans>
      </MenuItem>
      <MenuItem href="/ringx" isActive={pathname.startsWith('/ringx')}>
        <Trans>RingX</Trans>
      </MenuItem>
      {/* <MenuItem href="/launchpad" isActive={pathname.startsWith('/launchpad')}>
        <Trans>Launchpad</Trans>
      </MenuItem> */}
      <MenuItem href="/pools" isActive={isPoolActive}>
        <Trans>Pools</Trans>
      </MenuItem>
      <MenuItem href="/earn" isActive={pathname.startsWith('/earn')}>
        <div className="relative">
          <div className="absolute z-10 -right-4 -top-4">
            <NewIcon />
          </div>
          <Trans>Earn</Trans>
        </div>
      </MenuItem>
      <a target="_blank" href="https://duo.exchange/" className={styles.menuItem} rel="noreferrer">
        <Trans>Duo</Trans>
      </a>
      <a target="_blank" href={infoUrl} className={styles.menuItem} rel="noreferrer">
        <Trans>Info</Trans>
      </a>
      <Box display={{ sm: 'none', lg: 'none', xxl: 'flex' }} width="full">
        <MenuItem href="/wrap" dataTestId="wrap-nav-link" isActive={isWrapActive}>
          <Trans>Wrap</Trans>
        </MenuItem>
      </Box>
      <Box display={{ sm: 'none', lg: 'none', xxl: 'flex' }} width="full">
        <MenuItem href="/airdrop" dataTestId="airdrop-nav-link" isActive={isAirdropActive}>
          <Trans>Airdrop</Trans>
        </MenuItem>
      </Box>
      <Box display={{ sm: 'none', lg: 'none', xxl: 'flex' }} width="full">
        <MenuItem href="/bridge" dataTestId="bridge-nav-link" isActive={isBridgeActive}>
          <Trans>Bridge</Trans>
        </MenuItem>
      </Box>
      <Box display={{ sm: 'flex', lg: 'flex', xxl: 'none' }} width="full">
        <More />
      </Box>
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const navigate = useNavigate()

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <UniIcon
                width="48"
                height="48"
                data-testid="ring-logo"
                className={styles.logo}
                onClick={handleUniIconClick}
              />
            </Box>
            {!isNftPage && (
              <Box display={{ sm: 'flex', lg: 'none' }}>
                <ChainSelector />
              </Box>
            )}
            <Row display={{ sm: 'none', lg: 'flex' }}>
              <PageTabs />
            </Row>
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              {!isNftPage && (
                <Box display={{ sm: 'none', lg: 'flex' }}>
                  <ChainSelector />
                </Box>
              )}

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
